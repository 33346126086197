import { updateAllProperties } from '@/utils/utils'

export default class User {
  constructor (values) {
    updateAllProperties(this, values)
  }
  
  get fullName () {return (this.firstName || '').trim() + ' ' + (this.lastName || '').trim()}
  
  get phoneNumberFormatted () {
    try {
      if (!this.phoneNumber) return '';
      
      const cleaned = this.phoneNumber.replace(/\s+/g, '')
      let result = cleaned;
      if (cleaned.startsWith('61') || cleaned.startsWith('+61')) {
        result = cleaned.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4')
      } 
      else {
        result = cleaned.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')
      }
    
      return result
    } 
    catch (e) {
      return this.phoneNumber;
    }
  }

  get flatRoles () {return this.roles.map(item => item.name)}
  get isUser () {return this.hasRole('USER')}
  get isAdmin () {return this.hasRole('ADMIN')}
  get isSuperAdmin () {return this.hasRole('SUPER_ADMIN')}

  hasRole (role) {
    return this.flatRoles.includes(role)
  }

  static getNewUser() { 
    return new User({
      guid: null,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      roles: []
    })
  
  }

  static setClass (obj) {
    if (obj instanceof Object !== true) return
    Object.setPrototypeOf(obj, User.prototype)
    return obj
  }
  static setClasses (objs) {
    objs.forEach(obj => User.setClass(obj))
    return objs
  }
  static copy (obj) {
    const copy = JSON.parse(JSON.stringify(obj))
    User.setClass(copy)
    return copy
  }
}